import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/other-items/item';
import { Container } from '../gallery/gallery.css';

const OtherItems = ({ items }) => (
  <Container>
    <ul>
      {items.map((item, i) => (
        <Item {...item} key={i} />
      ))}
    </ul>
  </Container>
);

OtherItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OtherItems;
