import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './family-photo.css';

const FamilyPhoto = ({ familyPhotoUrl }) => {
  const handleClick = () => {
    console.log('hey');
  }
  return (
    <p>
      <a onClick={handleClick()} href={familyPhotoUrl} target="_blank" rel="noreferrer">
        Family photo
      </a>
    </p>
  );
};



FamilyPhoto.propTypes = {
  familyPhotoUrl: PropTypes.string.isRequired,
};

export default FamilyPhoto;
