import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import OtherItems from 'components/other-items';
import FamilyPhoto from 'components/family-photo';

const About3 = ({ data }) => (
  <Layout>
    <Head pageTitle={data.about3Json.title} />
    <Box>
      <h2 className="page-title">{data.about3Json.title}</h2>
      <div className="page-content-markdown"
        dangerouslySetInnerHTML={{
          __html: data.about3Json.content.childMarkdownRemark.html,
        }}
      />
      <OtherItems items={data.about3Json.otherItems} />
      <FamilyPhoto familyPhotoUrl={data.about3Json.familyPhotoUrl} />
    </Box>
    
  </Layout>
);

About3.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About3;

export const query = graphql`
  query About3Query {
    about3Json {
      title
      familyPhotoUrl
      content {
        childMarkdownRemark {
          html
        }
      },
      otherItems {
        title
        link
        newTab
      }
    }
  }
`;
