import React from 'react';
import PropTypes from 'prop-types';
import { Title, Copy } from './item.css';

const targetAttr = newT => {
  return newT ? '_blank' : '_self';
};

const Item = ({ title, link, newTab }) => (
  <li>
    <a href={link} target={targetAttr(newTab)}>
      {title}
    </a>
  </li>
);

Item.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  newTab: PropTypes.bool,
};

export default Item;
